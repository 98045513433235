import * as React from 'react'

import { AuthConfirm } from 'shared/pages'
import { getQueryStringValue } from 'shared/services'

import Layout from '../../layouts/landing'

import type { PageProps } from 'gatsby'

const Confirm = ({ location }: PageProps) => {
  const confirmationToken = getQueryStringValue(location.search, 'token', '')

  return (
    <Layout>
      <AuthConfirm confirmationToken={confirmationToken} />
    </Layout>
  )
}

export default Confirm
